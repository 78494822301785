section.questionsForm {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 .5rem;
    box-sizing: border-box;
    height: 100%;
}

article.question-form {
    width: 100%;
    height: 100%;
}

div.image-input-container {
    position: relative;
}

.helper-not-available-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--not-available-background);
}

.helper-not-available-container {
    opacity: .5;
    pointer-events: none;
    user-select: none;
}

.question-form-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

div.question-form-content div.question-form-title {
    margin: 0 0 1rem;
    height: 10vh;
}

div.question-form-content div.question-form-infos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 75vh;
}

div.question-form-infos div.questions-leftForm {
    width: 73%;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
}

div.question-form-infos div.questions-leftForm > div {
    margin: .5rem 0;
}

div.question-form-infos div.questions-rightForm {
    width: 25%;
    height: 100%;
}

div.question-form-infos div.questions-rightForm > div {
    width: 100%;
    padding: .5rem;
    margin: .5rem 0;
    box-sizing: border-box;
}

div.question-form-infos div.questions-rightForm > div.question-save-button {
    margin: 0;
}

div.question-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

div.question-active > div:last-child{
    display: flex;
    align-items: center;
    font-weight: bold;
}

div.question-active .question-active-switch {
    margin: 0 1rem;
}

div.question-save-button .save-button {
    width: 100%;
    font-size: 1.5rem;
    padding: .5rem;
    box-sizing: border-box;
    margin: .5rem 0;
}

div.question-save-button .delete-button {
    margin: 1rem;
}

div.card > span.card-title {
    position: relative;
    padding-right: .75rem;
}

div.text-input-container,
div.image-input-container,
div.question-category-selector,
div.question-level-selector,
div.question-language-selector,
div.question-form-content {
    position: relative;
}