.criterion-form {
    position: relative;
}

div.criterion-form-content {
    position: relative;
    min-width: 40vw;
    min-height: 300px;
    max-height: 80vh;
    overflow-y: auto;
    padding: .5rem;
}

div.criterion-form-footer {
    border-top: var(--border-sm);
}

div.criterion-form-footer .btn {
    margin-left: 1.5rem !important;
}

div.criterion-form-general,
div.criterion-form-data {
    margin: 0 1.5rem;
}

div.criterion-form-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    padding-top: .5rem;
    box-sizing: border-box;
}

div.criterion-form-info label {
    font-weight: 500;
}

div.criterion-form-info span {
    font-style: italic;
}

div.criterion-form-info>div:last-child {
    display: flex;
    align-items: center;
}

div.criterion-form-info span.criterion-form-info-specific svg {
    color: var(--middle-gray);
    font-size: 1.25em;
}

div.criterion-form-general div.criterion-position>input {
    width: 50px;
    box-sizing: border-box;
    text-align: center;
}

div.criterion-form-general div.criterion-position>label {
    left: 0;
}

.active-region>input {
    background: var(--fade-green) !important;
    color: var(--success-color) !important;
}

.region-input>input:hover {
    cursor: default;
}

.data-LOC-selector_dep>div.select-wrapper {
    padding-top: 0 !important;
    width: 100% !important;
    margin-left: 5px;
    box-sizing: border-box;
}

div.criterion-form-general div.criterion-form-and-switch-content {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin: 15px 0;
}

div.criterion-form-general div.criterion-form-and-switch-content div.left-content,
div.criterion-form-general div.criterion-form-and-switch-content div.right-content {
    box-sizing: border-box;
}

div.criterion-form-general div.criterion-form-and-switch-content div.left-content {
    width: 60%;
    margin-right: 30px;
    padding-top: 3px;
}

div.criterion-form-general div.criterion-form-and-switch-content div.right-content {
    width: 40%;
    padding-top: 10px;
}

div.criterion-form-general div.criterion-form-and-switch-content div.right-content div.funding-switch {
    margin-bottom: 10px;
}

div.criterion-form-general div.criterion-form-and-switch-content div.left-content div.criterion-name-and-position-content {
    margin-top: 15px;
}

div.criterion-name-and-position-content .formField {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

div.criterion-name-and-position-content div.criterion-name {
    width: calc(100% - (65px + 0.85rem));
    box-sizing: border-box;
    margin-right: 20px;
}

div.criterion-form-and-switch-content div.criterion-category-type {
    width: calc(100% - (65px + 0.85rem));
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.criterion-form-and-switch-content div.criterion-category-type div.category-form,
div.criterion-form-and-switch-content div.criterion-category-type div.type-form {
    width: 50%;
    box-sizing: border-box;
}

div.criterion-form-and-switch-content div.criterion-category-type div.category-form {
    margin-right: 10px;
}

div.criterion-form-and-switch-content div.criterion-category-type div.type-form {
    margin-left: 10px;
}

.criterion-num-value-min,
.criterion-obg-mandatory {
    margin-left: 0 !important;
}

.criterion-num-step,
.criterion-obg-not-mandatory {
    margin-right: 0 !important;
}

/*div.aids-causing-block-content {
    max-height: 80vh;
    overflow-y: auto;
}*/

/* ================================== Switch ================================== */

div.criterion-form-general div.criterion-switch-container {
    padding-right: 1rem;
    box-sizing: border-box;
}

div.criterion-switch-container .criterion-switch {
    padding-right: .3rem;
    margin-right: 5px;
}

div.criterion-switch-container span.criterion-switch-label {
    min-width: 70px;
}

/* ================================== TXT Inputs ================================== */


div.form-data-TXT div.form-data-TXT-displaymode {
    min-width: 20vw;
}

div.form-data-LOC div.data-TXT-list,
div.form-data-TXT div.data-TXT-list {
    width: 98%;
    height: fit-content;
    padding: 0 .5rem;
}

div.form-data-TXT div.data-TXT-list {
    max-height: 40vh;
    overflow-y: auto;
}

div.form-data-TXT div.data-TXT-list>div:nth-child(1) {
    width: 100%;
}

div.data-TXT-item>div.input-disactivated,
div.data-LOC-item>div.input-disactivated {
    cursor: not-allowed;
    pointer-events: none;
}

div.data-TXT-item>div.input-disactivated input,
div.data-LOC-item>div.input-disactivated input {
    background: var(--light-gray);
    text-decoration: line-through;
}


div.data-TXT-item .TXT-button,
div.data-LOC-item .LOC-button,
div.data-TXT-item .TXT-input,
div.data-LOC-item .LOC-input {
    margin: 1px 3px;
}

/* ================================== LOC Inputs ================================== */

div.criterion-form-data div.data-LOC-input,
div.criterion-form-data div.data-LOC-selector {
    padding: 0;
}

div.data-LOC-item .LOC-input>input,
.zone-input-field>input {
    cursor: default;
}

div.criterion-form-data div.data-LOC-zone,
div.criterion-form-data div.data-LOC-department-form,
div.criterion-form-data div.data-LOC-selector {
    min-width: 31%;
}

div.criterion-form-data div.data-LOC-selector_dep {
    margin-bottom: 1rem;
}

.criterion-category-selector,
.criterion-type-selector {
    width: 100%;
}

.criterion-category-selector {
    margin-right: 30px;
}

div.data-LOC-selector_dep .department-selector {
    width: 100%;
}

div.criterion-form-general .form-content {
    position: relative;
    height: 60px
}

div.criterion-form-general .form-tiny-content {
    position: relative;
    height: 55px
}

div.criterion-form-general .form-tiny-content .form-error {
    position: absolute;
    left: .5rem;
    bottom: 0;
}

div.criterion-form-general .error {
    position: absolute;
    bottom: 0;
    color: var(--red);
}

div.criterion-form-data div.form-data-SPECIFIC {
    min-height: 40vh;
}

div.criterion-form-data div.form-data-LOC {
    align-items: flex-start;
    position: relative;
    width: 60vw;
    overflow-y: hidden;
}

div.criterion-form-data div.form-data-LOC h5 {
    margin: 0 0 .25rem;
    text-align: left;
    position: relative;
}

div.criterion-form-data div.form-data-LOC h5 .region-add-button {
    position: absolute;
    top: -3px;
    left: 45px;
    color: var(--main-accent-color);
}

div.criterion-form-data div.form-data-LOC h5 .region-add-button:hover {
    cursor: pointer
}

div.form-data-LOC div.data-LOC-separator {
    width: 3%;
}

div.form-data-LOC .LOC-dep-number {
    width: 15%;
}

div.info-bulle-form-content {
    margin-bottom: 10px;
}


/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {}


@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 599px) {
    .criterion-form-info {
        flex-direction: column;
    }

    .criterion-form-info>div {
        padding: 3px 0;
    }
}

@media screen and (max-width: 425px) {}