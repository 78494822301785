div.profileTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

div.profileTable > div.profileTable-column {
    width: 100%;
    border-bottom: var(--border-sm);
}

div.profileTable > div.profileTable-column:first-child {
    width: 200%;
}

div.profileTable > div.profileTable-column > div {
    padding: .5rem;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.profileTable > div.profileTable-column:first-child > div {
    justify-content: flex-start;
}

div.profileTable div.profileTable-head-top:not(.profileTable-empty),
div.profileTable div.profileTable-head-left {
    font-weight: bold;
    background: var(--lighter-gray);
    box-shadow: var(--shadow-sm);
    cursor: help;
    position: relative;
}

div.profileTable div.profileTable-head-top > span.profileTable-title:hover {
    cursor: pointer;
    text-decoration: underline;
}

div.profileTable div.profileTable-head-top > span.profileTable-delete {
    cursor: pointer;
    color: var(--alert-color-clear);
    position: absolute;
    top: .25rem;
    right: .25rem;
}
div.profileTable div.profileTable-head-top > span.profileTable-delete:hover {
    color: var(--alert-color);
}

div.profileTable div.profileTable-head-left,
div.profileTable div.profileTable-content {
    border-top: var(--border-sm);
}

div.profileTable div.profileTable-head-left {
    cursor: auto;
}

div.profileTable div.profileTable-access {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    user-select: none;
}

div.profileTable div.profileTable-access.disabled{
    cursor: not-allowed;
}

div.profileTable > div.profileTable-column div.profileTable-access.profileTable-access--noaccess,
div.profileTable-legend > div.profileTable-legend-noaccess {
    color: #ebedee;
}

div.profileTable > div.profileTable-column div.profileTable-access.profileTable-access--read,
div.profileTable-legend > div.profileTable-legend-readaccess {
    color: #86c965;
}

div.profileTable > div.profileTable-column div.profileTable-access.profileTable-access--write,
div.profileTable-legend > div.profileTable-legend-writeaccess {
    color: #f59450;
}

div.profileTable-legend {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.profileTable-legend > div[class^="profileTable-legend-"] {
    padding: .25rem 10px;
}

div.profileTable-loading {
    width: 20vw;
    padding: 1rem 2rem;
    box-sizing: border-box;
    text-align: center;
    border-radius: var(--border-radius-sm);
    position: absolute;
    top: 1rem;
    left: 1rem;
}

div.profileTable-loading.working {
    color: var(--black);
}

div.profileTable-loading.valid {
    color: var(--white);
    background: var(--success-color);
}

.profileTable-legend svg {
    position: relative;
    top: 4px;
}