div.simulator-list-root {
    display: flex;
    width: 100%;
    padding: 10px !important;
}

div.simulator-list-root div.steps-content,
div.simulator-list-root div.simulator-info,
div.simulator-list-root div.simulator-activities {
    background: var(--main-background);
    border-radius: 14px;
    padding: 10px;
}

div.simulator-list-root div.steps-content {
    display: flex;
    width: calc(82% - 10px);
    margin-right: 10px;
    height: calc(96vh - 20px);
    box-sizing: border-box;
}

div.simulator-list-root div.simulator-info,
div.simulator-list-root div.simulator-activities {
    width: 100%;
    box-sizing: border-box;
    align-self: start;
}

div.simulator-list-root div.simulator-activities {
    margin-top: 10px;
    overflow-y: auto;
}

div.simulator-activities div.activities-item {
    font-size: .8em;
    margin: 5px 0
}

div.simulator-activities div.activities-item div.activities-user {
    width: 30%;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-right: 10px;
}

div.simulator-activities div.activities-item div.activities-others-infos {
    box-sizing: border-box;
    width: calc(70% - 10px);
}

div.simulator-activities div.activities-item span.activities-date {
    color: var(--text-color-notice)
}

div.steps-list {
    width: 25%;
}

div.steps-list div.steps-list-title {
    padding-right: 10px;
}

div.steps-list div.steps-list-content {
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

div.steps-list div.steps-list-content-with-loading {
    position: relative
}

div.questions-list {
    width: 75%;
    border: 1px solid var(--lighter-blue);
    border-radius: 16px;
}

div.steps-list-title,
div.questions-list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 20px;
}

div.questions-list-title {
    padding-left: 44px;
    padding-right: 15px;
    box-sizing: border-box;
}

div.steps-list-title span,
div.questions-list-title span {
    color: var(--lighter-black);
    font-weight: 500;
    font-size: 20px;
}

div.steps-list-title button,
div.questions-list-title button {
    margin: 0 !important;
    font-size: 12px !important;
}

div.questions-list-content-with-loading {
    display: flex;
    justify-content: center;
}

div.questions-headers {
    padding-bottom: 5px;
    border-bottom: 1px solid #E0E7EB;
}

div.questions-headers>div {
    color: var(--dark-gray);
    font-size: 12px;
    font-weight: bold;
}

div.questions-rows {
    padding-top: 10px;
    overflow-y: auto;
}

div.questions-rows div.question-row {
    margin-bottom: 10px
}

div.questions-headers>div:nth-child(1) {
    padding-left: 44px;
    box-sizing: border-box;
}

div.questions-headers>div:nth-child(1),
div.questions-rows div.question-row>div:nth-child(1) {
    width: 20%;
}

div.questions-headers>div:nth-child(2),
div.questions-rows div.question-row>div:nth-child(2) {
    width: 29%;
}

div.questions-headers>div:nth-child(3),
div.questions-rows div.question-row>div:nth-child(3) {
    width: 20%;
}

div.questions-headers>div:nth-child(4),
div.questions-rows div.question-row>div:nth-child(4) {
    width: 12%;
}

div.questions-headers>div:nth-child(5),
div.questions-rows div.question-row>div:nth-child(5) {
    width: 11%;
}

div.questions-headers>div:nth-child(6),
div.questions-rows div.question-row>div:nth-child(6) {
    width: 8%;
}

div.questions-rows div.question-row>div:nth-child(6) {
    display: flex;
    justify-content: center;
}

div.questions-rows div.question-row>div:nth-child(6) .action-button {
    padding: 3px !important;
}

div.questions-headers>div:nth-child(4),
div.questions-headers>div:nth-child(5),
div.questions-rows div.question-row>div:nth-child(4),
div.questions-rows div.question-row>div:nth-child(5) {
    display: flex;
    justify-content: center;
}

div.questions-headers>div:nth-child(6) {
    text-align: center;
}

div.questions-rows div.question-row>div:nth-child(1),
div.questions-rows div.question-row>div:nth-child(2),
div.questions-rows div.question-row>div:nth-child(3) {
    padding-right: 5px;
}

div.question-row div.draggable-icon-content {
    position: relative;
    width: 18px;
}

div.question-row div.draggable-icon-content .MuiSvgIcon-root {
    position: absolute;
    left: 0;
    top: -9px;
}

div.question-row div.question-position {
    width: 21px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: #A1B59C;
    border-radius: 6px;
    color: var(--main-background);
    font-size: 14px;
    margin-right: 5px;
}

div.question-row div.question-title {
    color: var(--link-color);
    font-size: 12px;
    font-weight: bold;
    width: calc(100% - 44px);
}

div.question-row div.question-title:hover {
    cursor: pointer;
}

div.question-row div.question-text {
    font-size: 11px;
    color: var(--dark-gray);
    font-weight: 500;
}

div.question-row div.question-criterion>div:first-child,
div.question-row div.question-criterion>div:last-child {
    font-size: 10px;
    color: var(--dark-gray);
}

div.question-row div.question-criterion>div:first-child {
    font-weight: bold;
}

.question-clone-icon {
    color: var(--main-accent-color);
}

.question-change-step-icon,
.question-delete-icon {
    color: var(--title-color)
}

div.simulator-info div.simulator-info-content {
    width: 95%;
    margin: 0 auto;
}

div.simulator-info div.simulator-info-content div.status-content {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

div.simulator-info div.simulator-info-content div.status-content>span:first-child {
    margin-right: 20px;
    color: var(--dark-gray);
    font-size: 13px;
}

div.simulator-info div.simulator-info-content div.status-content .flex-input {
    margin-right: 10px;
}

div.simulator-info div.simulator-info-content div.forms-content {
    margin-bottom: 20px;
}

div.simulator-info div.simulator-info-content div.forms-content .published-version-form {
    margin-bottom: 10px;
}

div.simulator-info div.simulator-info-content div.forms-content .published-version-form input {
    background: var(--clear-background);
    border-color: var(--clear-background);
}

div.simulator-info div.simulator-info-content div.forms-content .current-version-form .select-wrapper {
    padding-top: 0;
}

div.simulator-info div.simulator-info-content div.forms-content label {
    font-size: 13px;
    color: var(--darker-gray);
    margin-bottom: 3px;
}

div.simulator-info div.simulator-info-content>button {
    margin: 0 auto;
    background: var(--white) !important;
    color: var(--link-color);
    font-weight: bold;
    font-size: 13px;
    border-radius: 14px;
    border: 1px solid var(--link-color);
    display: flex;
    justify-content: center;
}

div.simulator-info div.simulator-info-content>button:hover:enabled {
    background: var(--link-color) !important;
    color: var(--white) !important;
}

div.simulator-info div.simulator-info-content .info-content {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

div.simulator-info div.simulator-info-content .info-content .info-item {
    margin-bottom: 5px;
}

div.simulator-info div.simulator-info-content .info-content .info-item>div:first-child {
    font-size: 12px;
    font-weight: 450;
    color: #C8D0C7
}

div.simulator-info div.simulator-info-content .info-content .info-item>div:last-child {
    font-size: 12px;
    font-weight: 450;
    color: var(--dark-gray);
}

div.simulator-info div.simulator-info-content .buttons-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 20px; */
    box-sizing: border-box;
}

div.simulator-info div.simulator-info-content .buttons-content button {
    margin: 0 !important;
    width: 50%;
    display: flex;
    justify-content: center;
}

div.simulator-info div.simulator-info-content .buttons-content>button {
    background: var(--white);
    color: var(--dark-gray);
    border: 1px solid var(--dark-gray);
    font-weight: bold;
    border-radius: 14px;
}

div.simulator-info div.simulator-info-content .buttons-content>button:hover:enabled {
    background: var(--dark-gray);
    color: var(--white)
}

div.simulator-info div.simulator-info-content .buttons-content>button:first-child {
    margin-right: 5px !important;
}

div.question-row div.draggable-icon-content .MuiSvgIcon-root {
    color: var(--deep-gray);
}

button.btn-version-disabled {
    color: var(--gray) !important;
    border: 1px solid var(--gray) !important;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (min-width: 1800px) {

    div.questions-headers>div:nth-child(2),
    div.questions-rows div.question-row>div:nth-child(2) {
        width: 33%;
    }

    div.questions-headers>div:nth-child(3),
    div.questions-rows div.question-row>div:nth-child(3) {
        width: 20%;
    }

    div.questions-headers>div:nth-child(4),
    div.questions-rows div.question-row>div:nth-child(4) {
        width: 11%;
    }

    div.questions-headers>div:nth-child(5),
    div.questions-rows div.question-row>div:nth-child(5) {
        width: 8%;
    }

    div.questions-rows div.question-row>div:nth-child(2) {
        padding-right: 30px;
        box-sizing: border-box;
    }

    div.questions-rows div.question-row .question-text {
        font-size: 13px;
    }

    div.questions-rows div.question-row .question-criterion>div {
        font-size: 12px;
    }

}