div.proposed-aid-out-of-folder {
    margin-bottom: 1.5rem
}

div.proposed-aid-in-folder,
div.proposed-aid-in-subfolder {
    margin-bottom: 0.75rem
}

div.proposed-aid div {
    display: flex;
    width: 75%;
    padding-left: 1rem;
    margin-top: 0.25rem;
    font-weight: bold;
}

div.proposed-aid .total-line {
    margin-left: auto;
}