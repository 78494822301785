article.stats {
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
}


/* ================================== Sstats table ================================== */

section.stats-table  div.stats-table-content {
    position: relative;
}
