div.aid-funding-with-criterions {
    width: 65vw;
    max-height: 77vh;
    box-sizing: border-box;
    overflow-y: auto;
    border-bottom: 1px solid var(--light-gray);
}

div.aid-funding-with-criterions div.aid-funding-plant-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

div.aid-funding-with-criterions div.aid-funding-plant-content div.criterion-fixed-form-content div.select-wrapper {
    width: 140px;
}

div.aid-funding-with-criterions div.criterion-fixed-form-content .criterion-fixed-form {
    margin-top: 11px;
}

div.criterion-fixed-form div.MuiInputAdornment-root>p.MuiTypography-root {
    padding-top: 2px !important;
}

div.criterion-fixed-form div.Mui-disabled {
    background: var(--input-secondary-background-disabled) !important
}

div.aid-funding-with-criterions div.criterion-fixed-form-content .criterion-fixed-form input {
    height: 38px !important;
    box-sizing: border-box;
}

div.aid-funding-with-criterions div.form-content div.criterions-funding-content,
div.aid-funding-with-criterions div.form-content div.fixed-funding-content {
    box-sizing: border-box;
    padding: 10px 20px;
    border-bottom: 1px solid var(--light-gray);
}

div.aid-funding-with-criterions div.form-content div.fixed-funding-content {
    margin-top: 15px;
}

div.aid-funding-with-criterions div.form-content div.criterions-funding-content div.criterions-form-funding,
div.aid-funding-with-criterions div.form-content div.fixed-funding-content div.fixed-form-funding {
    width: 100%;
    display: grid;
    column-gap: 70px;
    padding: 0 35px;
    box-sizing: border-box;
}

div.aid-funding-with-criterions div.form-content div.criterions-funding-content div.criterions-form-funding,
div.aid-funding-with-criterions div.form-content div.plant-fixed-funding-content div.fixed-form-funding {
    grid-template-columns: repeat(2, 1fr) !important;
}

div.aid-funding-with-criterions div.form-content div.fixed-funding-content div.fixed-form-funding {
    grid-template-columns: repeat(3, 1fr);
}

div.aid-funding-with-criterions div.form-content div.criterions-funding-content h3,
div.aid-funding-with-criterions div.form-content div.fixed-funding-content h3 {
    margin-top: 0;
    margin-left: 0;
    color: var(--title-color);
}

div.aid-funding-with-criterions div.form-content div.fixed-funding-content div.aid-funding-plan-fixed-form-content {
    margin-bottom: 80px;
}

div.criterions-form-funding>div,
div.fixed-form-funding {
    margin-bottom: 20px;
}

div.aid-funding-with-criterions div.form-content label.MuiFormControlLabel-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

div.aid-funding-with-criterions div.form-content label.MuiFormControlLabel-root span.MuiButtonBase-root {
    padding-left: 0 !important;
}

div.aid-funding-with-criterions div.form-content>div.aid-funding-scale div.aidCriteria-list-item div.aidCriteria-slider {
    padding-bottom: 0 !important;
}

div.aid-funding-with-criterions-skeleton-content {
    padding: 1rem;
}

@media screen and (max-width: 1024px) {
    div.aid-funding-with-criterions div.form-content div.criterions-funding-content div.criterions-form-funding {
        grid-template-columns: repeat(1, 1fr);
    }

}