div.textarea-root {
    position: relative;
}

div.label-content {
    position: absolute;
    top: 15px;
    left: 22px
}

div.focus-label-content {
    position: absolute;
    top: -8px;
    left: 10px;
    background: var(--white);
    font-size: 11px;
    padding: 0 6px;
}

div.textarea-root .focus-text {
    color: var(--main-accent-color);
}

.textarea-root label {
    position: relative;
}

.textarea-root label span {
    margin-right: 15px;
}

.quill {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid var(--light-gray);
    border-radius: 7px;
}

.quill:hover {
    outline: 1px solid var(--light-gray);
}

.focused-quill-form {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid var(--main-accent-color) !important;
    box-sizing: border-box;
    border-radius: 7px;
    outline: 1px solid var(--main-accent-color);
}

.focused-quill-form:hover {
    outline: 1px solid var(--main-accent-color);
}

.ql-toolbar.ql-snow {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border: none !important;
}

.ql-container {
    border: none !important;
}

.ql-toolbar.ql-snow>span {
    display: flex;
    justify-content: flex-end;
    margin-right: 0 !important;
}

.ql-toolbar.ql-snow>span button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ql-toolbar.ql-snow>span button:hover,
.ql-toolbar.ql-snow>span button.ql-active {
    background: var(--dark-maroon);
    border: 1px solid var(--less-light-gray);
}

.ql-toolbar.ql-snow>span button:hover .ql-fill,
.ql-toolbar.ql-snow>span button.ql-active .ql-fill {
    fill: var(--darker-gray) !important;
}

.ql-toolbar.ql-snow>span button:not(:last-child) {
    margin-right: 5px;
}

.ql-snow .ql-stroke {
    stroke: var(--darker-gray) !important;
}

.ql-editor {
    height: 50px !important;
}

.ql-container {
    font-size: 1rem !important;
    color: var(--text-color);
}

.ql-editor.ql-blank::before {
    font-style: normal !important;
}