section.bo-data-title{
    position: relative;
    /* padding-top: 12px; */
    /* padding-bottom: 12px; */
}

section.bo-data-title button{
    border: none;
}

section.bo-data-title div button{
    position: absolute;
    top: 2px;
    right: 0;
}

article.list-layout {
    position: relative;
    padding: 1rem;
}

article.list-layout h3{
    text-transform: uppercase
}

article.list-layout .list-layout-filter-content {
    display: flex;
    align-items: center
}

article.list-layout .list-layout-filter-content .input-form,
article.list-layout .list-layout-filter-content .select-wrapper {
    margin: 0;
    margin-right: 10px;
    width: 12%;
    height: 100%;
    padding-top: 10px;
}

article.list-layout .button-filter-action-content {
    width: 16%;
    display: flex;
    align-items: center;
    padding-top: 10px;
}

button.delete-button-reference-data-content{
    border: none;
    padding: 0;
    background: none;
    cursor: pointer
}

/*button.delete-button-reference-data-content .MuiSvgIcon-root{
    color: red
}*/