.reference-data-form-root{
    padding: .5rem;
    min-width: 40vw
}

.reference-data-form-root .reference-data-forms{
    width: 100%;
    margin: 0 auto
}

.reference-data-form-root .reference-data-forms .input-required-error{
    padding-left: 8px;
    color: red;
}

.reference-data-form-root .reference-data-form-footer{
    border-top: var(--border-sm);
}