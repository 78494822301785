section.dashboard {
    padding: 1rem;
}

section.dashboard h3 {
    margin-top: .5rem;
}

div.dashboard-datetime {
    color: var(--text-color-notice);
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 6vh;
    min-height: 50px;
    padding-bottom: 1rem;
}

div.dashboard-datetime span.dashboard-time {
    font-size: 2.5rem;
}

div.dashboard-datetime span.dashboard-date {
    font-size: 1.5rem;
}

div.dashboard-rightPanel {
    width: 25%;
    /*padding: 0 1rem;*/
    padding: 1rem;
    box-sizing: border-box;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.dashboard-help {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    height: 20vh;
    font-size: 1.2em;
}

div.dashboard-help > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

div.dashboard-help a {
    padding: 1rem;
    box-sizing: border-box;
    color: var(--text-color-notice);
    text-decoration: none;
    width: 50%;
    font-weight: 500;
}

div.dashboard-help a svg {
    padding-right: .5rem;
}

div.dashboard-help a:hover {
    color: var(--link-color-hover);
}

div.dashboard-help svg {
    position: relative;
    top: 4px;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
    div.dashboard-datetime {
        width: 70%;
    }
}

@media screen and (max-height: 800px) {
    div.dashboard-help {
        font-size: 1rem;
    }

    section.dashboard h3 {
        font-size: 1.17rem;
    }
}

@media screen and (max-width: 1024px) {
    div.dashboard-help {
        font-size: 1em;
    }

    div.dashboard-datetime {
        width: 50%;
    }

    div.dashboard-datetime span.dashboard-time {
        font-size: 2.5rem;
    }

    div.dashboard-datetime span.dashboard-date {
        font-size: 1rem;
    }

    div.dashboard-rightPanel,
    div.dashboard-help {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 768px) {

    div.MuiGrid-item.stats-coloredCard .card img {
        width: 32px;
    }

}

@media screen and (max-width: 425px) {

    div.dashboard-datetime {
        width: 30%;
    }

    div.advancedStats .card {
        min-height: 50px;
        padding: 10px;
    }

    div.MuiGrid-item.stats-coloredCard,
    div.MuiGrid-item.stats-bar,
    div.MuiGrid-item.stats-pie {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        padding-top: 12px;
    }

    div.MuiGrid-item.stats-coloredCard .MuiStack-root {
        /*flex-direction: row;*/
        align-items: flex-end;
        position: relative;
    }

    div.MuiGrid-item.stats-coloredCard .MuiStack-root > div.MuiBox-root:first-child {
        position: absolute;
        left: 1rem;
    }

    div.MuiGrid-item.stats-coloredCard .card .MuiTypography-subtitle2,
    div.MuiGrid-item.stats-coloredCard .card .MuiTypography-h3 {
        width: 70%;
        text-align: center;
    }
}