.question-form-root {
    width: 60vw;
    margin: 0 auto;
    padding-top: 10px;
}

.question-form-root .question-form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
}

.question-form-root .elements-content {
    height: 80vh;
    overflow-y: auto;
}

.question-form-root .question-form-footer .saving-button {
    margin-left: 0 !important;
}

.question-form-root .question-and-criteria-form,
.question-form-root .question-dependency-form {
    display: flex;
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
}

.question-form-root .question-and-criteria-form {
    margin-bottom: 5px;
}

.question-and-criteria-form .question-form,
.question-and-criteria-form .criteria-form,
.question-dependency-form .dependency-label-form,
.question-dependency-form .dependency-value-form,
.question-dependency-form .dependency-value-form-loading {
    width: 50%;
    box-sizing: border-box
}

.question-dependency-form .dependency-label-form {
    padding-top: 15px;
}

.question-dependency-form .dependency-value-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative
}

.question-dependency-form .dependency-value-form-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.question-dependency-form .dependency-label-form .content-title {
    width: calc(100% - 70px);
}


.question-dependency-form .dependency-label-form {
    border-right: 1px solid var(--main-accent-color);
    box-sizing: content-box !important;
}

.dependency-label-form .content-form {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    margin-bottom: 5px;
    position: relative;
}

.dependency-label-form .content-form .active-mark {
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background: var(--main-accent-color);
}

.dependency-label-form .content-form .select-wrapper {
    padding-top: 0;
    width: calc(100% - 70px);
}

.dependency-label-form .content-form .dependency-action-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    width: 70px;
}

.dependency-action-content .delete-dependency-icon {
    color: var(--title-color);
    cursor: pointer;
}

.dependency-action-content>div:last-child {
    background: var(--less-light-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
}

.dependency-action-content>div.active {
    background: var(--main-accent-color) !important;
}

.dependency-action-content div .consult-dependency-icon {
    color: var(--white);
    width: 20px;
}

.question-form .content-title,
.criteria-form .content-title,
.dependency-label-form .content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 15px;
}

.dependency-label-form .content-title button {
    margin: 0;
}

.question-and-criteria-form .criteria-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.question-and-criteria-form .criteria-form .content-title {
    align-self: flex-start;
}

.question-and-criteria-form .form-block {
    margin-bottom: 10px;
    width: calc(100% - 70px);
}

.criteria-form-case-2 .form-block .select-wrapper {
    width: calc(100% - 35px) !important;
}

.question-form .formField,
.possible-answers-content .form-content .formField,
.dependency-value-form .formField {
    padding-top: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.dependency-value-form div.dependency-value-content,
.dependency-value-form div.dependency-value-content-TXT {
    width: calc(100% - 70px);
}

.dependency-value-form div.dependency-value-content .answers-list,
.dependency-value-form div.dependency-value-content-TXT .answers-list {
    width: 100%;
}

.dependency-value-form div.dependency-value-content .txt-value {
    width: 100%;
}

.dependency-value-form div.dependency-value-content-TXT .txt-value {
    width: calc(100% - 35px) !important;
}

.dependency-value-form div.dependency-value-content .active-criteria-value,
.dependency-value-form div.dependency-value-content-TXT .active-criteria-value,
.txt-additional-forms .active-criteria-value {
    background: var(--main-accent-color);
    color: var(--white) !important;
    font-size: 12px !important;
}

.txt-additional-forms .active-criteria-value:hover {
    background: var(--main-accent-color) !important;
}

.dependency-value-form div.dependency-value-content .formField,
.dependency-value-form div.dependency-value-content-TXT .formField {
    margin: 0 !important;
    margin-bottom: 5px !important;
}

.dependency-value-form div.dependency-value-content-TXT .icon-button-content {
    width: 35px;
}

.dependency-value-form div.dependency-value-content-TXT .action-button {
    padding: 3px;
}

.question-form .question-title-form {
    width: 50%;
    margin-right: 20px !important;
    padding: 0;
}

.question-form .switch-content {
    display: flex;
    align-items: center;
    width: calc(50% - 20px);
    box-sizing: border-box;
}

.question-form .switch-content span {
    margin-left: 15px;
}

.criteria-form .select-wrapper {
    padding-top: 0 !important;
}

.question-form-root .error,
.dependency-value-content .error,
.dependency-value-content-TXT .error {
    color: var(--red)
}

.possible-answers-content {
    margin-top: 10px;
}

.possible-answers-content .MuiRadio-root {
    padding: 0 !important;
    margin: 0 !important;
}

.possible-answers-content .title-content,
.possible-answers-content .form-content {
    display: flex;
    justify-content: space-between;
}

.question-form .form-block .form-title,
.criteria-form .form-block .form-title,
.possible-answers-content .title-content,
.dependency-value-form .form-title {
    font-size: 13px;
    margin-bottom: 5px;
}

.obg-form-content {
    width: 80% !important;
}

.possible-answers-content .form-content .possible-answer-form {
    width: 80%;
    padding: 0 !important;
}

.possible-answers-content .form-content .possible-answer-radio {
    margin: 0;
}

.possible-answers-content .form-content {
    margin-bottom: 5px;
}

.txt-additional-forms {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 110;
    background: var(--white);
    border: 1px solid var(--main-accent-color);
    border-radius: 18px;
    width: 100%;
    height: 99.5%;
    box-sizing: border-box;
}

.txt-additional-content {
    position: relative;
}

.txt-additional-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.txt-additional-body {
    box-sizing: border-box;
    overflow-y: auto;
}

.dependency-value-form .MuiToggleButton-root,
.txt-additional-body .MuiButtonBase-root {
    margin-bottom: 5px !important;
    border: 1px solid var(--less-light-gray) !important;
    border-radius: 10px !important;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding-left: 15px !important;
    text-transform: none;
    font-size: 12px !important;
}

.txt-additional-body .MuiButtonBase-root {
    width: 95%;
    margin: 0 auto;
}

.answers-list .Mui-selected .answer-info {
    color: var(--white);
}

.criterion-values-loading-content {
    width: 100%;
}