/* ================================== GENERAL ================================== */

html,
body {
    padding: 0;
    margin: 0;
    font-weight: 300;
}

html {
    font-size: 13px;
}

body {
    font-family: 'Rubik', 'Roboto', 'Helvetica Neue', sans-serif;
    /*font-family: 'Montserrat', 'Raleway', 'Open Sans', 'Roboto', sans-serif;*/
    /*-webkit-font-smoothing: subpixel-antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
}

a {
    color: var(--link-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0 0 0 3px;
}

input[type="number"] {
    text-align: right;
    -moz-appearance: textfield;
}

div#root {
    position: absolute;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
}

section.container {
    width: 100%;
    height: 100%;
}

aside.menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 8vw;
    z-index: 10;
}

section.content {
    position: absolute;
    left: 8vw;
    top: 0;
    width: 92vw;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
}

div.separator,
div.separator-wide {
    box-sizing: border-box;
    border-bottom: var(--border-sm);
}

div.separator {
    width: 90%;
    margin: 1rem 0 1rem 5%;
}

div.separator.thick {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

div.separator-wide {
    width: 100%;
    margin: 1rem 0;
}

div.vertical-separator:after {
    content: '';
    margin: .25rem 1rem;
    height: calc(100% - .5rem);
    position: absolute;
    border-right: var(--border-sm);
    top: 0;
}

.error-access {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
}

.error-access .card {
    margin: 1em 0;
}

.error-access h1 {
    margin: 1em;
    font-size: 1.2em;
}

.help-text {
    color: var(--gray);
    font-style: italic;
    padding: .25rem 0 .5rem;
}

.bold {
    font-weight: 600;
}

/* ================================== Utilities ================================== */
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.w-100 {
    width: 100% !important;
}

.w-80 {
    width: 80% !important;
}

.w-50 {
    width: 50% !important;
}

.h-100 {
    height: 100%;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    margin: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.me-0 {
    margin-right: 0 !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.hidden {
    display: none;
}

.visibility-hidden {
    visibility: hidden !important;
}

.center {
    text-align: center !important;
}

.text-truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.text-success {
    color: var(--success-color)
}

.text-warning {
    color: var(--warning-color)
}

.text-danger {
    color: var(--alert-color)
}

/* ================================== Scrollbar ================================== */
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--light-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--clear-accent-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--dark-gray);
}

/* ================================== Card ================================== */

.card {
    box-shadow: var(--shadow-sm);
    background: var(--white);
    padding: .4rem;
    box-sizing: border-box;
    border-radius: var(--border-radius-xl);
}

.card.blue {
    color: var(--card-blue-color);
    background: var(--card-blue-background);
}

.card.green {
    color: var(--card-green-color);
    background: var(--card-green-background);
}

.card.red {
    color: var(--card-red-color);
    background: var(--card-red-background);
}

.card.yellow {
    color: var(--card-yellow-color);
    background: var(--card-yellow-background);
}

.card.link-card {
    display: block;
    text-decoration: none;
}

.card.link-card:hover {
    box-shadow: var(--shadow-md);
}

/* ================================== Buttons ================================== */

.btn {
    padding: .5rem 1rem;
    margin: .5rem;
    background: var(--darker-gray);
    color: var(--white);
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: var(--border-radius-md);
    line-height: 1.2em;
    display: flex;
    align-items: center;
    transition: background 200ms ease;
}

.btn-lg {
    padding: .8rem 1.5rem;
    font-size: 1.2em;
}

.btn-tiny {
    border-radius: var(--border-radius-xs);
}

.btn:hover {
    background: var(--light-gray);
    color: var(--black);
    box-shadow: var(--button-shadow-hover);
}

.btn.default {
    background: var(--secondary-accent-color);
}

.btn.default:hover {
    background: var(--main-accent-color);
    color: var(--white);
}

.btn.alert {
    background: var(--alert-color);
}

.btn.alert:hover {
    background: var(--alert-color-hover);
    color: var(--white);
}

.btn.warning {
    background: var(--warning-color);
}

.btn.warning:hover {
    background: var(--warning-color-hover);
    color: var(--white);
}

.btn.success {
    background: var(--success-color);
}

.btn.success:hover {
    background: var(--success-color-hover);
    color: var(--white);
}

.btn.green {
    background: var(--fade-green);
    color: var(--success-color);
}

.btn.red {
    background: var(--fade-red);
    color: var(--alert-color);
}

.btn.green:hover {
    background: var(--success-color);
    color: var(--black);
}

.btn.red:hover {
    background: var(--alert-color);
    color: var(--black);
}

.btn.white {
    background: #ffffff;
    border: 1px solid var(--gray);
    color: var(--black);
}

.btn.disabled {
    pointer-events: none;
    user-select: none;
    cursor: wait;
    background: var(--gray);
}

.btn.disabled:hover {
    background: var(--gray);
}

.btn svg {
    padding: 0 3px;
}

/* ================================== BADGES ================================== */

.badge {
    padding: .25rem;
    margin: .2rem;
    background: var(--badge-default);
    color: var(--black);
    outline: none;
    border: none;
    border-radius: 8px;
    cursor: help;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
}

.badge.optionBadge {
    max-width: 60px;
    font-size: .8rem;
    padding: .15rem;
    margin: .1rem .5rem .1rem .1rem;
}

.badge.aliceBlue {
    background: var(--badge-aliceBlue);
}

.badge.lightBlue {
    background: var(--badge-lightBlue);
}

.badge.darkTurquoise {
    background: var(--badge-darkTurquoise);
}

.badge.turquoise {
    background: var(--badge-turquoise);
}

.badge.blue {
    background: var(--badge-blue);
}

.badge.lightSkyBlue {
    background: var(--badge-lightSkyBlue);
}

.badge.red {
    background: var(--badge-red);
}

.badge.orange {
    background: var(--badge-orange);
}

.badge.yellow {
    background: var(--badge-yellow);
}

.badge.purple {
    background: var(--badge-purple);
}

.badge.silver {
    background: var(--badge-silver);
}

.badge.blueGray {
    background: var(--badge-blueGray);
}

.badge.green {
    background: var(--badge-green);
}

.badge.white {
    background: var(--white);
}

.badge.black {
    background: var(--black);
    color: var(--white);
}

/* ================================== React-select ================================== */

.reactSelect-custom-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    width: 60%;
    padding: 20%;
    font-weight: bold;
    color: var(--white);
}

/* ================================== FLEX ================================== */

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-sa {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-grow {
    flex-grow: 1;
}

.align-items-start {
    align-items: start;
}

.align-items-baseline {
    align-items: baseline;
}

/* ================================== MUI Radio ================================== */
span.MuiRadio-root.Mui-checked {
    color: var(--main-accent-color);
}

/* ================================== MUI Table ================================== */

.MuiTableCell-head {
    font-size: 0.75rem !important;
}

table.MuiTable-root.compact .MuiTableCell-head {
    font-size: .7rem !important;
    word-break: break-word;
    max-width: 125px;
    min-width: 125px;
    box-sizing: border-box;
    padding: 0.2rem 0.6rem;
}

table.MuiTable-root.compact td {
    padding: 0 2px;
}

table.MuiTable-root.compact .MuiSelect-select,
table.MuiTable-root.compact .MuiOutlinedInput-input,
table.MuiTable-root.compact .MuiSelect-select .MuiChip-label {
    font-size: .8rem !important;
}

table.MuiTable-root.compact .MuiOutlinedInput-input {
    min-height: 1rem;
}

table.MuiTable-root.compact .MuiFormControl-root {
    margin: 0;
}

table.MuiTable-root.compact .selectMultiple .MuiSelect-outlined {
    padding-top: 3px;
    padding-bottom: 2px;
    min-height: 31px;
}

table.MuiTable-root.compact .MuiChip-filledDefault {
    height: 30px;
}

/* ================================== DataTable Title ================================== */

section.bo-data-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

section.bo-data-title h3 {
    margin: .75rem .75rem 1.25rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 500;
}

/* ================================== DataTable filters ================================== */

section.bo-data-table div.bo-data-filters {
    display: flex;
    justify-content: center;
    height: 8vh;
    align-items: baseline;
}

div.bo-data-filters .reactSelect-custom__menu {
    z-index: 10;
}

div.bo-data-filters>div {
    position: relative;
    width: 20%;
    z-index: 10;
}

div.bo-data-filters>div:last-child {
    width: 8%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

div.bo-data-filters div.formField[class*="reactSelect-"] {
    width: 100%;
}

/* ================================== DataTable table ================================== */

section.bo-data-table div.bo-data-table-content {
    position: relative;
}

a.bo-data-table_clickable,
span.bo-data-table_clickable {
    text-decoration: none;
    color: var(--link-color);
    cursor: pointer;
    font-weight: bold;
}

.rdt_TableCell,
.rdt_TableCol {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.rdt_TableCell {
    font-size: 1rem !important;
}

.rdt_TableCell:first-child,
.rdt_TableCol:first-child {
    padding-left: 10px !important;
}

.rdt_TableRow {
    height: 40px;
    line-height: 40px;
}

/* ================================== Tabs ================================== */

section.content.bo-with-tabs {
    margin-top: 4vh;
}

section.content.bo-with-tabs>article:not(.bo-tabs) {
    width: 100%;
    height: 96vh;
    padding: 0 1rem;
    box-sizing: border-box;
}

article.bo-tabs {
    position: fixed;
    top: 0;
    left: 7.5vw;
    width: 92.5vw;
    padding: 0;
    box-sizing: border-box;
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: var(--shadow);
}

article.bo-tabs div.bo-tabs-item {
    background: var(--white);
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    transition: var(--transition-background);
}

article.bo-tabs div.bo-tabs-item a {
    text-decoration: none;
    font-style: normal;
    color: var(--text-color);
    font-size: 1.25rem;
    font-weight: 500;
    padding: .5rem 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}

article.bo-tabs div.bo-tabs-item:hover {
    background: var(--tab-background-dark);
}

article.bo-tabs div.bo-tabs-item:hover a {
    color: var(--main-accent-color);
    font-weight: bold;
}

article.bo-tabs div.bo-tabs-item:has(a.active) {
    border-bottom: var(--tab-border-md);
    border-bottom-color: var(--main-accent-color);
}

article.bo-tabs div.bo-tabs-item a.active {
    color: var(--main-accent-color);
    font-weight: bold;
}

/* ================================== SLIDERS ================================== */


.rc-slider-track {
    background: var(--lighter-accent-color);
}

.rc-slider-handle {
    opacity: 1;
    border-color: var(--light-accent-color);
    background: var(--light-accent-color);
    box-shadow: var(--shadow-md);
    z-index: 0 !important;
}

.slider-input-value-container,
.slider-input-value-container input {
    color: var(--text-color-notice);
    font-style: italic;
}

.rc-slider-handle:focus {
    outline: none;
    box-shadow: var(--shadow-md);
}

.slider-input-value-container input {
    outline: none;
    border: 2px solid #f1f1f1;
    transition: 250ms ease;
    border-radius: 5px;
    padding: 2px 3px;
    box-shadow: none;
}

.slider-input-value-container input:hover,
.slider-input-value-container input:focus {
    border: 2px solid var(--success-color) !important;
    transition: 500ms ease !important;
}

.rc-slider-handle:focus-visible {
    box-shadow: 0 0 0 3px var(--clear-accent-color);
}

.rc-slider-handle:hover {
    border-color: var(--secondary-accent-color);
}

.rc-slider-handle:active {
    box-shadow: 0 0 5px var(--clear-accent-color);
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px var(--transparent-accent-color);
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
.rc-slider-handle-click-focused,
.rc-slider-handle {
    border-color: var(--light-accent-color);
}


/* ----------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------- Animations ------------------------------------------------ */
/* ----------------------------------------------------------------------------------------------------------- */

@keyframes elements-dialog-fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes elements-dialog-fade-out {
    0% {
        opacity: 1;
        visibility: visible
    }

    99.998% {
        opacity: 0;
        visibility: visible
    }

    99.999%,
    100% {
        opacity: 0;
        visibility: hidden
    }
}

@keyframes elements-dialog-transform {
    0% {
        transform: none
    }

    1% {
        transform: scale(.8)
    }

    100% {
        transform: scale(1)
    }
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
    html {
        font-size: 12px !important;
    }

    section.bo-data-table div.bo-data-filters {
        height: 7vh;
        min-height: 65px;
    }

    .rdt_TableRow {
        height: 35px;
        line-height: 35px;
    }

    section.bo-data-title h3 {
        margin: .75rem;
    }

    .rdt_Pagination {
        min-height: 40px;
    }

    .reactSelect-custom__control {
        min-height: 30px !important;
    }
}

@media screen and (max-height: 800px) {
    html {
        font-size: 11px;
    }

    section.bo-data-table div.bo-data-filters {
        height: 50px;
        min-height: 40px;
    }
}

@media screen and (max-width: 1024px) {
    html {
        font-size: 11px !important;
    }

    aside.menu {
        width: 4vw;
    }

    section.content {
        left: 4vw;
        width: 96vw;
    }

    section.bo-data-table div.bo-data-filters {
        align-items: center;
    }

}

@media screen and (max-width: 768px) {
    html {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 425px) {}