article.parameters input {
    text-align: center;
    color: var(--text-color);
    position: relative;
}

div.parameters-item {
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
    padding: .5rem 1rem;
    font-size: 1rem;
}

div.parameters-item.fullwidth {
    grid-template-columns: 100%;
}

div.parameters-item>div.parameters-switch {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

div.parameters-item>div.parameters-value {
    display: flex;
    align-items: center;
}

div.parameters-item>div.parameters-value div.input-value {
    margin: 0;
    padding: 0;
    width: 100%;
}

div.parameters-item>div.parameters-url {
    display: flex;
    align-items: center;
}

div.parameters-item>div.parameters-url div.input-url {
    margin: 0;
    padding: 0;
    width: 78%;
}

div.parameters-item>div.parameters-url div.input-percentage {
    margin: 0;
    margin-top: 3px;
    padding: 0;
    width: calc(100% - 100px);
}

div.parameters-item>div.parameters-url input {
    height: 15px;
}

div.parameters-item>div.parameters-url:not(div.with-play) input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

div.parameters-item>div.parameters-url button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 33px;
}

div.parameters-item>div.parameters-percentage button.saving-button {
    width: 100px !important;
}

div.parameters-item>div.parameters-url div.play-button {
    border: none;
    width: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #2e7d32;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {}

@media screen and (max-height: 800px) {
    div.parameters-item>div.parameters-switch {
        flex-direction: column;
    }
}


@media screen and (max-width: 1024px) {
    div.parameters-item>div.parameters-switch {
        flex-direction: column;
    }

    div.parameters-item>div.parameters-url input {
        padding: .5rem !important;
    }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 425px) {}