div.aid-funding-criterion-form-root {
    width: 100%;
}

div.aid-funding-criterion-form-root div.num-content div.rc-slider {
    margin-top: 7px;
}

div.aid-funding-criterion-form-root div.select-wrapper div.reactSelect-custom__multi-value__label {
    overflow: visible !important;
    text-overflow: initial !important;
    white-space: normal !important;
}