div.organization-form {
    min-width: 50vw;
    position: relative;
}

div.organization-form div.organization-form-content{
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
}

div.organization-form div.organization-form-content div.organization-form-part {
    width: 50%;
    padding: .5rem;
    box-sizing: border-box;
}
div.organization-form div.separator {
    width: 80%;
    border-bottom: var(--border-sm);
}

div.organization-form div.organization-form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem 2rem;
    box-sizing: border-box;
    width: 100%;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
}


@media screen and (max-width: 1024px) {


}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 425px) {

}