div.step-root {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #D9E1E7CC;
    border-radius: 16px;
    min-height: 60px;
    margin-bottom: 10px;
}

div.active-step {
    border: 1px solid var(--main-accent-color) !important;
}

div.step-root div.step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 34px;
    background: var(--clear-background);
    border-radius: 12px;
    font-size: 16px;
    margin-left: 5px;
    color: #A1B59C
}

div.step-root div.step-name {
    color: var(--link-color);
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    width: calc(100% - 118px);
}

div.step-root div.step-name span:hover {
    cursor: pointer;
}

div.step-root div.questions-count {
    font-size: 17px;
    color: var(--main-accent-color);
    font-weight: 500;
    margin-left: 5px;
    width: 20px;
}

div.step-root .action-button {
    padding: 3px !important;
    margin-right: 5px;
    margin-left: 3px;
}

div.step-root div.draggable-icon-content {
    width: 18px;
}

div.step-root div.draggable-icon-content .MuiSvgIcon-root {
    color: var(--deep-gray);
}

.step-delete-icon {
    color: var(--title-color) !important;
}

.step-update-icon {
    color: var(--main-accent-color) !important;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

/* Cas de mon écran */
@media screen and (max-width: 1440px) {
    div.step-root div.step-name {
        font-size: 14px
    }
}

/* Cas des écrans plus petits **/
@media screen and (max-width: 1024px) {
    div.step-root div.step-name {
        font-size: 12px;
    }
}