div.aid-funding-table-root,
div.aid-funding-table-root table {
    width: 100%;
    overflow-x: auto;
}

div.aid-funding-table-root table {
    border-spacing: 0;
    table-layout: fixed;
}

div.aid-funding-table-root table tr.title-row>th:not(:last-child),
div.aid-funding-table-root table tr.value-row>td:not(:last-child) {
    padding: 5px 10px;
    width: 150px;
    text-align: left;
    padding-right: 50px;
}

div.aid-funding-table-root table tr.title-row>th.centered-title {
    text-align: center !important;
}

div.aid-funding-table-root table tr.value-row>td.centered-title {
    text-align: right !important;
}

div.aid-funding-table-root table tr.title-row>th {
    border-bottom: 1px solid var(--input-color-disabled);
    border-top: 1px solid var(--input-color-disabled);
}

div.aid-funding-table-root table tr.value-row>td:not(:last-child) {
    border-bottom: 1px solid var(--input-color-disabled);
}

div.aid-funding-table-root table tr.value-row:hover {
    background: var(--table-hover-secondary-background);
    cursor: pointer;
}