.simulator-view .table tr td:first-child {
    font-weight: bold;
}

.simulator-view .table tr td:last-child {
    text-align: right;
}

.simulator-view h2 small {
    font-size: 0.7rem;
    color: darkgray;
    position: relative;
    top: -2px;
}

.simulator-view a {
    color: var(--link-color);
}

.simulator-view .aid-folder-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.simulator-view .aid-folder-container .subfolders-and-aids-content,
.simulator-view .aid-folder-container .aids-in-subfolder-container {
    padding-left: 10px;
}

.simulator-view div.sufbolder-container {
    margin-bottom: 0.75rem;
}