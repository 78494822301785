div.loading-overlay {
    position: fixed;
    inset: 0;
    z-index: 1000;
    background: var(--overlay-background);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: wait;
}

div.loading-overlay div.overlay-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--main-accent-color);
}

div.loading-overlay div.overlay-content > svg {
    font-size: 6rem;
}

div.loading-overlay div.overlay-content > div {
    font-size: 1.5rem;
}

div.loading-overlay div.overlay-brand {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 1010;
    width: 5vw;
}

div.loading-overlay div.overlay-brand img {
    width: 100%;
}

/* =========================================== LAODING =========================================== */
div.loading {
    position: absolute;
    inset: 0;
    z-index: 1000;
    background: var(--overlay-background-transparent);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: wait;
}

div.loading.loading-fixed {
    position: fixed;
}

div.loading-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: var(--clear-accent-color);
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
}


@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
    div.loading-overlay div.overlay-brand {
        width: 10vw;
    }
}

@media screen and (max-width: 425px) {
    div.loading-overlay div.overlay-brand {
        width: 15vw;
    }

}