div.user-profile-root {
    width: 100%;
    padding: 8px 15px;
    padding-bottom: 12px;
    box-sizing: border-box;
}

div.user-profile-root .profile-info-divider {
    width: 90%;
    margin: 0 auto;
}

div.user-profile-root div.basic-info-content button,
div.user-profile-root div.password-info-content button {
    margin-top: 15px;
}

div.user-profile-root div.basic-info-content button {
    margin-bottom: 15px;
}

div.basic-info-content>div div.error,
div.password-info-content>div div.error {
    padding-left: 10px;
    color: var(--red)
}

div.basic-info-content>div div.warning {
    padding-left: 10px;
    color: rgb(241, 196, 15);
}

div.basic-info-content div.same-address,
div.basic-info-content div.different-address {
    padding-left: 10px;
}


div.basic-info-content div.same-address {
    color: var(--button-primary-background-hover)
}

div.basic-info-content div.different-address {
    color: var(--red);
}