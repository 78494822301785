div.advancedStats {
    padding: 0 1rem;
}

div.advancedStats .card {
    min-height: 150px;
    max-height: 400px;
    padding: 30px;
    height: 100%;
}
div.advancedStats .card.chart,
div.advancedStats .card.pie {
    padding: 15px 20px 40px;
    max-height: 450px;
}

div.advancedStats .card.chart div.advanced-stats-chart {
    height: 325px;
}

div.advancedStats .card .MuiTypography-h3 {
    /*font-size:2rem!important;*/
    font-weight: 700;
}

div.advancedStats .card .MuiTypography-subtitle2 {
    font-size: 1.2rem!important;
    opacity: 0.64;
    font-weight: 600;
    text-align: center;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
}


@media screen and (max-height: 800px) {
    div.advancedStats .card {
        max-height: 150px;
        padding: 20px;
    }

    div.advancedStats .advancedStats-img {
        height: 32px;
    }

    div.advancedStats .card .MuiTypography-h3 {
        font-size:2rem;
    }
}


@media screen and (max-width: 1024px) {
    div.advancedStats {
        padding: 1rem;
    }

    div.MuiGrid-item.stats-bar {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
    }

    div.MuiGrid-item.stats-pie{
        width: 50%;
        max-width: 50%;
        flex-basis: 50%;
    }

    div.advancedStats .card {
        min-height: 100px;
        padding: 15px;
    }

    div.MuiGrid-item.stats-coloredCard .card img {
        width: 48px;
    }
    div.advancedStats .card .MuiTypography-h3 {
        font-size: 2.5rem;
    }

    div.advancedStats .card .MuiTypography-subtitle2 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 768px) {
    div.advancedStats .card {
        min-height: 100px;
        padding: 15px;
    }

    div.MuiGrid-item.stats-coloredCard .card img {
        width: 32px;
    }
}

@media screen and (max-width: 425px) {
    div.advancedStats .card {
        min-height: 50px;
        padding: 10px;
    }

    div.MuiGrid-item.stats-coloredCard,
    div.MuiGrid-item.stats-bar,
    div.MuiGrid-item.stats-pie {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        padding-top: 12px;
    }

    div.MuiGrid-item.stats-coloredCard .MuiStack-root {
        /*flex-direction: row;*/
        align-items: flex-end;
        position: relative;
    }

    div.MuiGrid-item.stats-coloredCard .MuiStack-root > div.MuiBox-root:first-child {
        position: absolute;
        left: 1rem;
    }

    div.MuiGrid-item.stats-coloredCard .card .MuiTypography-subtitle2,
    div.MuiGrid-item.stats-coloredCard .card .MuiTypography-h3 {
        width: 70%;
        text-align: center;
    }

}