article.organizations {
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
}

div.organizationsTable a {
    color: var(--link-color);
    text-decoration: none;
    cursor: pointer;
}

div.organizationsTable a:hover {
    color: var(--link-color-hover);
    text-decoration: underline;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
}


@media screen and (max-width: 1024px) {


}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 425px) {

}