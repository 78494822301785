aside.menu {
    background: var(--menu-background-gradient);
    height: 100vh;
    box-sizing: border-box;
    overflow-x: visible;
}

div.menu-title {
    height: 10vh;
}

div.menu-title img {
    width: 100%;
}

nav.menu-nav {
    height: 79vh;
    width: 8vw;
    overflow-x: visible;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

div.menu-title,
nav.menu-nav>a,
div.menu-footer {
    padding: 10px 20px;
    box-sizing: border-box;
    text-align: center;
    width: 95%;
}

nav.menu-nav>a {
    min-height: 5vh;
    font-style: unset;
    font-size: 1rem;
    text-decoration: none;
    color: var(--menu-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: .75rem 0;
    padding: 2rem .5rem;
    transition: var(--transition-all);
}

nav.menu-nav>a svg {
    font-size: 1.5rem;
    padding: .5rem;
}

nav.menu-nav>a.active {
    background: var(--accent-background);
    background: var(--accent-background-gradient);
    font-weight: bold;
    color: var(--light-black);
    border-radius: 0 10px 10px 0;
    z-index: 20;
    width: 100%;
}

nav.menu-nav>a:hover:not(.active) {
    color: var(--secondary-accent-color);
    font-weight: bold;
    background: var(--overlay-background-transparent);
}

/* =============================== menu-footer =============================== */
div.menu-footer {
    height: 10vh;
    font-size: .9rem;
    padding: 0;
    color: var(--text-color-notice);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.menu-footer div.menu-version {
    height: 1vh;
    text-align: center;
    font-style: italic;
}

div.menu-footer div.menu-user {
    height: 9vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 .5rem;
    cursor: help;
    border-top: var(--black) 1px solid;
}

div.menu-footer div.menu-user span {
    padding: .1rem;
}

div.menu-footer div.menu-user span.menu-user-name {
    font-weight: bold;
    font-size: 1em;
}

div.menu-footer div.menu-user span.menu-user-role {
    color: var(--dark-accent-color);
}

div.menu-footer div.menu-user span.menu-user-society {
    font-style: italic;
}

div.menu-footer a.menu-user-logout {
    color: var(--text-color-notice);
    text-align: right;
    padding: 0 .5rem;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
}

div.menu-footer a.menu-user-logout:hover {
    color: var(--link-color-hover);
}

svg.principal-menu-icon {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
    div.menu-title {
        padding: 5px 10px;
    }

    nav.menu-nav>a svg {
        font-size: 1.25rem;
    }

    div.menu-footer {
        font-size: .8rem;
        overflow: hidden;
    }
}


@media screen and (max-width: 1024px) {

    aside.menu {
        background: var(--menu-background);
    }

    div.menu-title {
        display: none;
    }

    nav.menu-nav {
        height: 85vh;
        width: 4vw;
    }

    div.menu-footer {
        height: 14vh;
    }

    nav.menu-nav .menu-item span.menu-text {
        display: none;
    }


    nav.menu-nav>a {
        padding: 1rem .25rem;
        height: 10vh;
    }

    nav.menu-nav>a.active {
        border-radius: 0;
    }

    nav.menu-nav>a:hover span.menu-text {
        display: block;
        position: absolute;
        left: 4vw;
        border-radius: var(--border-radius-md);
        background: var(--menu-background);
        color: var(--main-accent-color);
        padding: 1rem;
    }

    div.menu-footer div.menu-user {
        align-items: center;
        justify-content: space-around;
        height: 9vh;
        position: relative;
    }

    div.menu-footer div.menu-user .menu-user-name>span,
    div.menu-footer div.menu-user .menu-user-society {
        display: none;
    }

    div.menu-footer div.menu-user:hover .menu-user-name>span,
    div.menu-footer div.menu-user:hover .menu-user-society {
        display: block;
        position: absolute;
        left: 4vw;
        background: var(--menu-background);
        color: var(--menu-text-color);
        padding: 1rem;
        z-index: 1;
        width: 6vw;
        overflow: hidden;
    }

    div.menu-footer div.menu-user:hover .menu-user-name>span {
        top: 0;
        z-index: 2;
    }

    div.menu-footer div.menu-version {
        height: 4vh;
    }

    div.menu-footer a.menu-user-logout {
        padding: 0 .1rem;
        text-align: center;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 425px) {}