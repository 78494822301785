.modal:has(.aid-form) {
    left: 5vw;
    position: absolute !important;
}

.modal:has(.modal-close-confirm) {
    left: 0;
    position: relative !important;
}

article.aids {
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
    box-sizing: border-box;
}

.quickfilter-wrap {
    position: absolute;
    right: 0;
}

.quickfilter-wrap .MuiChip-label {
    font-size: 1rem;
}

section.bo-data-title .creation-button-content {
    display: flex;
    width: 40vw
}

.MuiListItemIcon-root {
    min-width: 20px !important
}

/* ================================== Aids table ================================== */

section.aids-table div.aids-table-content {
    position: relative;
}

.status-row.status-1 {
    background-color: #c8c8c8;
}

.status-row.status-2 {
    background-color: #fce09b;
}

.status-row.status-3 {
    background-color: rgb(252, 153, 153);
}

.status-row.status-4 {
    background-color: rgb(166, 210, 144);
}

.btn-duplicate-aid .MuiCircularProgress-root {
    width: 14px !important;
    height: 14px !important;
    font-size: 1rem;
}

.aidsTable .folder>div:nth-child(2)>div:first-child,
.aidsTable .subfolder-in-folder>div:nth-child(2)>div:first-child {
    overflow: visible !important
}

.aidsTable .aids-in-subfolder>div:nth-child(2) {
    padding-left: 26px !important;
}

.aidsTable .aids-folder-title {
    display: flex;
    align-items: center;
}

.aidsTable .aids-folder-title .aids-count {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    padding: 5px;
    font-weight: bold;
    border-radius: 5px;
    background: var(--clearer-accent-color)
}

.aidsTable div.subfolder-row {
    display: flex;
    height: 40px;
    line-height: 40px;
}

.aidsTable .rdt_TableRow>div:first-child button {
    width: auto !important;
    height: auto !important;
    padding: 0 !important
}

.aidsTable .rdt_TableHeadRow>div:first-child,
.aidsTable .rdt_TableRow>div:first-child {
    flex: none !important;
    min-width: auto !important;
}

.aidsTable .rdt_TableHeadRow>div:first-child {
    width: 24px !important;
    padding: 0 !important
}

.aidsTable .rdt_ExpanderRow .rdt_TableHead {
    display: none;
}

.aidsTable .rdt_ExpanderRow .rdt_TableRow {
    display: flex;
    align-items: center
}

.aidsTable .rdt_ExpanderRow .rdt_TableRow:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.aidsTable .rdt_ExpanderRow .subfolder-in-folder>div:not(.aids-in-subfolder-table):first-child {
    left: 24px;
}

.aidsTable .rdt_ExpanderRow .subfolder-in-folder>div:nth-child(2) {
    left: 24px;
}

.aidsTable .aid-out-folder {
    display: flex !important;
    align-items: center !important;
}

.aidsTable .aid-out-folder>div:first-child {
    display: block;
    width: 24px;
    height: 24px;
    position: relative
}

.aidsTable .aid-out-folder>div:first-child svg {
    visibility: hidden;
}

.aidsTable .aid-out-folder>div:first-child::before {
    content: url('../../../public/img/icon/red-aid-icon.svg');
    display: block;
    position: absolute;
    left: 8px;
    top: 6px;
    width: 18px;
    height: 18px;
}

.aidsTable .rdt_ExpanderRow .aids-in-folder>div:first-child,
.aidsTable .rdt_ExpanderRow .aids-in-subfolder>div:first-child {
    visibility: hidden
}

.aidsTable .rdt_ExpanderRow .aids-in-folder>div:nth-child(2)::before,
.aidsTable .rdt_ExpanderRow .aids-in-subfolder>div:nth-child(2)::before {
    content: url('../../../public/img/icon/red-aid-icon.svg');
    display: block;
    margin-right: 15px;
}

.aidsTable .rdt_TableRow {
    height: 35px !important;
    min-height: auto !important;
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1250px) {
    section.bo-data-title .creation-button-content {
        display: flex;
        /* flex-direction: column; */
        width: auto;
    }

    .quickfilter-wrap {
        display: flex;
        justify-content: flex-end;
        width: 75%
    }

    button.btn span.aids-button-action {
        display: none;
    }
}

@media screen and (max-width: 1440px) {
    button.btn span.test-action {
        display: none;
    }
}