div.yes-or-no-root {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 11px;
    width: 49px;
    height: 25px;
    border-radius: 10px;
}

div.yes-root {
    background: var(--light-green);
}

div.no-root {
    background: var(--light-pink);
}