article.activity {
    width: 75%;
    /*padding: 0 1rem;*/
    padding: 1rem;
    box-sizing: border-box;
    height: 55vh;
}

section.activity-table {
    padding: 1rem;
    height: 100%;
}

section.activity-table div.activity-filters {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: .5rem;
    box-sizing: border-box;
}

div.activity-filters div.filter-item {
    padding: .2rem 1rem;
    cursor: pointer;
    border: 1px solid var(--light-gray);
    border-radius: var(--border-radius-sm);
}

div.activity-filters div.filter-item.all {
    color: var(--black);
}

div.activity-filters div.filter-item.all.active,
div.activity-filters div.filter-item.all:hover {
    background: var(--success-color);
    color: var(--white);
}

div.activity-filters div.filter-item.info {
    color: var(--dark-accent-color);
}

div.activity-filters div.filter-item.info.active,
div.activity-filters div.filter-item.info:hover {
    background: var(--dark-accent-color);
    color: var(--white);
}

div.activity-filters div.filter-item.warning {
    color: var(--warning-color);
}

div.activity-filters div.filter-item.warning.active,
div.activity-filters div.filter-item.warning:hover {
    background: var(--warning-color);
    color: var(--white);
}

div.activity-filters div.filter-item.alert {
    color: var(--alert-color);
}

div.activity-filters div.filter-item.alert.active,
div.activity-filters div.filter-item.alert:hover {
    background: var(--alert-color);
    color: var(--white);
}

section.activity-table div.activity-table-content {
    position: relative;
    height: 92%;
}

section.activity-table div.activity-table-content table {
    border-collapse: collapse;
    width: 100%;
    max-height: 100%;
    text-align: left;
}

div.activity-table-content tbody {
    max-height: 41vh;
    overflow-y: scroll;
    font-size: .9rem;
    display: block;
}

div.activity-table-content tbody td {
    padding: .5rem .25rem;
    width: 10%;
}

div.activity-table-content tbody td:nth-child(4),
div.activity-table-content thead th:nth-child(4) {
    width: 50%;
}

div.activity-table-content thead th {
    padding: .25rem;
    font-size: .9rem;
    text-align: left;
}

div.activity-table-content tr {
    border-bottom: 1px solid var(--light-gray);
    display: table;
    width: 100%;
}

div.activity-table-content tr.log-error {
    background-color: var(--table-alert-color);
}

div.activity-table-content tr.log-error:hover {
    background-color: var(--table-alert-color-hover);
}

div.activity-table-content tr.log-warning {
    background-color: var(--table-warning-color);
}

div.activity-table-content tr.log-warning:hover {
    background-color: var(--table-warning-color-hover);
}


/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
}

@media screen and (max-height: 800px) {

    div.activity-table-content tbody {
        max-height: 210px;
    }
}


@media screen and (max-width: 1024px) {
    article.activity {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 425px) {

}