article.criterion {
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
}

div.aids-with-error-content {
    padding: 0.5rem;
    box-sizing: border-box;
    max-height: 80vh;
    overflow-y: auto;
}

div.aids-with-error-content div.alert-message {
    width: 100%;
}

div.aids-with-error-content div.alert-message div.MuiPaper-root {
    display: flex;
    align-items: center;
}

/* ================================== Criterion table ================================== */

section.criterion-table div.criterion-table-content {
    position: relative;
}

div.criterionTable div.criterion-id {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

div.criterionTable div.criterion-specific {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

div.criterionTable div.criterion-specific svg {
    color: var(--middle-gray);
    padding-left: .25em;
    padding-bottom: .2em;
    font-size: 1.2em;
}