.simulation-state {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    overflow: hidden;
    background: gray;
    display: inline-block;
    margin-right: 0.5rem;
}

.simulation-state.status-1 {
    background: var(--orange-container);
}

.simulation-state.status-2 {
    background: var(--green-container);
}

.simulation-state.status-3 {
    background: var(--blue-container);
}

.simulations .MuiMenuItem-root {
    padding-bottom: 0;
    padding-top: 0;
}

.MuiGrid2-root.legend-saved,
.MuiGrid2-root.legend-finished,
.MuiGrid2-root.legend-progress {
    cursor: help;
}

span.state-saved,
span.state-finished,
span.state-progress {
    padding: 4px 10px;
}

.MuiGrid2-root.legend-saved .MuiButton-root,
span.state-saved {
    color: var(--dark-blue);
    background: var(--blue-container);
}

.MuiGrid2-root.legend-finished .MuiButton-root,
span.state-finished {
    color: var(--dark-green);
    background: var(--green-container);
}

.MuiGrid2-root.legend-progress .MuiButton-root,
span.state-progress {
    color: var(--dark-orange);
    background: var(--orange-container);
}

span.simulator-info span {
    width: 50%;
}

span.simulator-info span:last-child {
    text-align: right;
}