.required-star {
    font-size: 1.25rem;
    top: 0;
    right: 0;
    position: absolute;
    display: inline;
    color: var(--alert-color);
}

.required-text {
    font-size: .8rem;
    color: var(--alert-color);
}

/* ================================== INPUT/TEXTAREA ================================== */

.formField {
    display: flex;
    position: relative;
    padding: 13px 0 0;
    margin: 10px;
    box-sizing: border-box;
}

.formField--nopadding {
    padding: 0 !important;
}

.formField-input>input,
.formField-textarea>textarea {
    outline: none;
    width: 100%;
    font-size: 1rem;
    border-radius: 7px;
    color: var(--text-color);
}

.formField-input>input.disabled,
.formField-textarea>textarea.disabled {
    /* pointer-events: none; */
    cursor: not-allowed;
    background: var(--input-background-disabled) !important;
    color: var(--input-color-disabled) !important;
}

.formField-textarea>textarea {
    resize: none;
    min-height: 5rem;
    margin: 5px 0 0;
    overflow-y: scroll;
    padding: 10px;
    box-sizing: border-box;
}

.formField-input>label,
.formField-textarea>label {
    cursor: text;
    position: absolute;
    transition: 0.25s;
    color: var(--dark-gray);
    user-select: none;
}

.formField-input>input[type="file"]~label {
    font-size: .8rem;
    top: 0;
}

div.multiline-input-character-count {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end
}

div.multiline-input-character-count div.character-count-value {
    width: 70px;
}

/* ---------- Input focused ---------- */

.formField-input>input.formField--focused~label,
.formField-textarea>textarea.formField--focused~label {
    transition: all .2s ease;
}

/* ================================== FUTURIST STYLE ================================== */


.formField-input.formField-style-futurist>input,
.formField-textarea.formField-style-futurist>textarea {
    border: none;
    border-bottom: 1px solid var(--light-gray);
    background: transparent;
    color: var(--text-color-secondary);
    padding: 5px 10px;
}

.formField-input.formField-style-futurist>label,
.formField-textarea.formField-style-futurist>label {
    /* Specific rules for futurist style */
}

.formField-input.formField-style-futurist>span.formField-underline,
.formField-textarea.formField-style-futurist>span.formField-underline {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--main-accent-color);
    transition: 0.2s;
}

/* ---------- Input focused ---------- */

.formField-input.formField-style-futurist>input.formField--focused~span.formField-underline,
.formField-textarea.formField-style-futurist>textarea.formField--focused~span.formField-underline {
    width: 100%;
    transition: 0.1s;
    left: 0;
}

.formField-input.formField-style-futurist>input.formField--focused~label,
.formField-textarea.formField-style-futurist>textarea.formField--focused~label {
    font-size: .8rem;
    transform: translateY(-17px);
}

/* ================================== CLASSIC STYLE ================================== */

.formField-input.formField-style-classic>input,
.formField-textarea.formField-style-classic>textarea {
    border: 1px solid var(--light-gray);
    background: var(--white);
    padding: .85rem .85rem .85rem 15px;
}

.formField-input.formField-style-classic.input-tiny>input,
.formField-textarea.formField-style-classic.input-tiny>textarea {
    padding: .5rem .5rem .5rem 15px;
}

.formField-input.formField-style-classic.input-url>input {
    /*color: var(--link-color);*/
    /*text-decoration: underline;*/
}

.formField-input.formField-style-classic>input:hover,
.formField-textarea.formField-style-classic>textarea:hover {
    outline: 1px solid var(--light-gray);
}


.formField-input.formField-style-classic>label,
.formField-textarea.formField-style-classic>label {
    padding: 12px;
    transition: all .2s ease;
    left: 10px;
    /*text-transform: uppercase;*/
}

.formField-input.formField-style-classic.input-tiny>label,
.formField-textarea.formField-style-classic.input-tiny>label {
    padding: 7px;
    font-size: .8rem;
}

.formField-input.formField-style-classic>input::placeholder {
    color: var(--middle-gray);
}

/* ---------- Input focused ---------- */

.formField-input.formField-style-classic>input.formField--focused~label,
.formField-textarea.formField-style-classic>textarea.formField--focused~label {
    top: 2px;
    font-size: .8rem;
    padding: 0 15px 0 5px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(0, 0, 0, 0) 50%);
    transition: all .2s ease;
    color: var(--gray);
}

.formField-input.formField-style-classic.input-tiny>input.formField--focused~label,
.formField-textarea.formField-style-classic.input-tiny>textarea.formField--focused~label {
    top: 4px;
}

.formField-textarea.formField-style-classic>textarea.formField--focused~label {
    top: 7px;
}

.formField-input.formField-style-classic>input:focus~label,
.formField-textarea.formField-style-classic>textarea:focus~label {
    color: var(--dark-accent-color);
}

.formField-input.formField-style-classic>input:focus,
.formField-textarea.formField-style-classic>textarea:focus {
    outline: 2px solid var(--main-accent-color);
}

/* ============================================================================================================== */
/* =============================================== MEDIA QUERIES ================================================ */
/* ============================================================================================================== */

@media screen and (max-width: 1440px) {
    .formField {
        margin: 5px;
    }

    .formField-input.formField-style-classic>input.formField--focused~label,
    .formField-textarea.formField-style-classic>textarea.formField--focused~label {
        top: 4px;
    }

    .select-wrapper.not-empty .select-label {
        top: 1px;
    }
}

@media screen and (max-height: 800px) {
    .formField {
        padding: 8px 0 0;
    }

    .formField-input.formField-style-classic>input.formField--focused~label,
    .formField-textarea.formField-style-classic>textarea.formField--focused~label {
        top: 0 !important;
    }
}

@media screen and (max-width: 1024px) {

    .formField-input.formField-style-classic>label,
    .formField-textarea.formField-style-classic>label {
        padding: 5px;
        font-size: 1rem;
        line-height: 1rem;
        vertical-align: middle;
    }

    .formField-input.formField-style-classic>input.formField--focused~label,
    .formField-textarea.formField-style-classic>textarea.formField--focused~label {
        top: 5px;
    }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 425px) {}